import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Take out trash',
  [StandardizedTask.SMOKE]: 'Room smells like smoke',
  [StandardizedTask.SOFA_1]: 'Prepare the sofa for 1 PAX',
  [StandardizedTask.SOFA_2]: 'Prepare the sofa for 2 PAX',
  [StandardizedTask.BABY_BED]: 'Bring baby bed',
  [StandardizedTask.DRYING_RACK]: 'Bring drying rack',
  [StandardizedTask.TOWELS]: 'Bring towels',
  [StandardizedTask.IRON_AND_BOARD]: 'Bring iron & board',
  [StandardizedTask.TOILET_PAPER]: 'Bring toilet paper',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Check no-show from 10 AM',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Party Room - check for damage',
  [StandardizedTask.LCO_11_30]: 'LCO at 11:30 AM',
  [StandardizedTask.LCO_12]: 'LCO at 12 PM',
  [StandardizedTask.QUARANTINE]: 'QUARANTINE - HSK do not enter room',
  [StandardizedTask.COFFEE_PODS]: 'Bring extra coffee pods',
  [StandardizedTask.KITCHEN_TOWEL]: 'Bring kitchen towel',
  [StandardizedTask.BLANKET]: 'Bring a blanket',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Check for mail',
  [StandardizedTask.BABY_CHAIR]: 'Bring baby chair',
  [StandardizedTask.TURN_HEATING_ON]: 'Turn heating on before CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Bring sorry treatment',
  [StandardizedTask.REFILL_HSK_HUB]: 'Refill HSK hub',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Bring fan to room',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: 'Do not disturb guest',
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Prioritize room cleaning',
  [StandardizedTask.SHAMPOO_REFILL]: 'Shampoo refill',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Shower gel refill',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Body lotion refill',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'Bring kitchen towel',
  [StandardizedTask.BRING_FRYING_PAN]: 'Bring frying pan',
  [StandardizedTask.BRING_COOKING_POT]: 'Bring cooking pot',
  [StandardizedTask.BRING_WATER_GLASS]: 'Bring water glass / glasses',
  [StandardizedTask.BRING_WINE_GLASS]: 'Bring wine glass / glasses',
  [StandardizedTask.BRING_CUP]: 'Bring cup',
  [StandardizedTask.BRING_PLATES]: 'Bring plates',
  [StandardizedTask.BRING_BOWL]: 'Bring bowl',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'Bring paper towels',
  [StandardizedTask.CLEAN_ELEVATOR]: 'Clean elevator',
  [StandardizedTask.CLEAN_ENTRANCE]: 'Clean entrance',
  [StandardizedTask.CLEAN_HALLWAYS]: 'Clean hallways',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'Clean laundry room',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'Clean luggage lockers',
  [StandardizedTask.CLEAN_STAIRS]: 'Clean stairs',
  [StandardizedTask.CLEAN_TOILET]: 'Clean toilet',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'Clean public toilets',
  [StandardizedTask.CLEAN_TERRACE]: 'Clean terrace',
  [StandardizedTask.CLEAN_BALCLONY]: 'Clean balcony',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'Clean washing machines & dryers (laundry room)',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'Double-check room',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'Check laundry delivery',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'Check expiration date of consumables',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'Check (reset) luggage lockers',
  [StandardizedTask.CHECK_MAILBOX]: 'Check mailbox',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'Refill essential closet',
  [StandardizedTask.REFILL_DETERGENT]: 'Refill detergent',
  [StandardizedTask.ORGANIZE_STORAGE]: 'Organize storage',
  [StandardizedTask.ORDER_CRISPS]: 'Order crisps',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Order juice / soft drink',
  [StandardizedTask.ORDER_BEER]: 'Order beer',
  [StandardizedTask.ORDER_WATER]: 'Order water',
  [StandardizedTask.ORDER_EARPLUGS]: 'Order earplugs',
  [StandardizedTask.ORDER_SWEETS]: 'Order sweets / Candy Kittens',
  [StandardizedTask.ORDER_COFFEE]: 'Order coffee / Grind',
  [StandardizedTask.ORDER_TEA]: 'Order tea / Hoogly',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'Take out dirty laundry',
  [StandardizedTask.WATER_FLOWERS]: 'Water the flowers',
  [StandardizedTask.WATER_GARDEN]: 'Water the garden',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Bathroom fan loud',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Light not working',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Sink is blocked',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Showerdrain is blocked',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Bathroom Filter',
  [StandardizedDamageReport.SINK]: 'Sink',
  [StandardizedDamageReport.TOILET]: 'Toilet',
  [StandardizedDamageReport.SHOWER]: 'Shower',
  [StandardizedDamageReport.STOVE]: 'Stove',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Fridge/Minibar',
  [StandardizedDamageReport.DOOR]: 'Door',
  [StandardizedDamageReport.WINDOW]: 'Window',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Lightbulb',
  [StandardizedDamageReport.CHAIRS]: 'Chairs',
  [StandardizedDamageReport.HEATING]: 'Heating',
  [StandardizedDamageReport.TV]: 'TV',
  [StandardizedDamageReport.TABLES]: 'Tables',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'HSK Team',
  [Team.MAINTENANCE]: 'Maintenance Team',
  [Team.OPERATIONS]: 'Operations Team',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kitchen',
  [DamageReportArea.BED]: 'Bed',
  [DamageReportArea.BATHROOM]: 'Bathroom',
  [DamageReportArea.SEATING_AREA]: 'Seating Area',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Walls/Ceiling/Floor',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Clean',
  [DamageReportActionRequired.REPLACE]: 'Replace',
  [DamageReportActionRequired.REPAIR]: 'Repair',
  [DamageReportActionRequired.CHECK]: 'Check',
  [DamageReportActionRequired.OTHER]: 'Other',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'High',
};

const popupNotifications = {
  taskDeletionNotification: 'Task has been successfully deleted',
  lostAndFoundDeletionNotification: 'Lost & found item has been successfully deleted',
  damageReportDeletionNotification: 'Damage report has been successfully deleted',
  taskCreationNotification: 'Task has been successfully created',
  lostAndFoundCreationNotification: 'Lost & found item has been successfully created',
  damageReportCreationNotification: 'Damage report has been successfully created',
  taskCompletionNotification: 'Task has been successfully completed',
  lostAndFoundCompletionNotification: 'Lost & found item has been successfully resolved',
  damageReportCompletionNotification: 'Damage report has been successfully resolved',
  taskUpdateNotification: 'Task has been successfully updated',
  lostAndFoundUpdateNotification: 'Lost & found item has been successfully updated',
  damageReportUpdateNotification: 'Damage report has been successfully updated',
  pleaseSelectRoomsNotification: 'Please select rooms',
  noShowReportNotification: 'No show successfully reported for room {{number}}',
  checkInReportNotification: 'Check in successfully reported for room {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Damage report successfully scheduled for {{date}}',
  hskDelayNotification: 'Successful HSK delay for room {{number}}. Check in time is updated to {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'More filters',
  resetAllFilters: 'Reset all filters',
  reset: 'Reset',
  cleaned: 'Cleaned',
  allRoomsCleaned: `All rooms cleaned`,
  dirty: 'Dirty',
  readyToClean: 'Ready to Clean',
  stayoverReadyToClean: 'Stayover - Ready to Clean',
  occupiedCleaningToday: 'Occupied - Cleaning Today',
  readyToInspect: 'Ready to Inspect',
  stayoverInspection: 'Stayover Inspection',
  vacant: 'Vacant',
  occupied: 'Occupied',
  clean: 'Clean',
  daily: 'Daily',
  weekly: 'Weekly',
  biweekly: 'Bi-Weekly',
  monthly: 'Monthly',
  none: 'None',
  tasks: 'Tasks',
  notifications: 'Notifications',
  more: 'More',
  select: 'Select',
  property: 'Property',
  result: 'Result',
  results: 'Results',
  properties: 'Properties',
  rooms: 'Rooms',
  team: 'Team',
  dueDate: 'Due Date',
  date: 'Date',
  close: 'Close',
  search: 'Search',
  login: 'Login',
  loading: 'Loading...',
  anErrorHasOccurred: 'An error has occurred',
  chooseDate: 'Choose Date',
  selectDate: 'Select date',
  selectADate: 'Select a Date',
  markAsClean: 'Mark as clean',
  markAsReadyToInspect: 'Mark as ready to inspect',
  viewTasks: 'View tasks',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Room log`,
  taskLog: `Task log`,
  seeDetails: 'See details',
  assignedTasks: 'Assigned tasks',
  delete: 'Delete',
  deleteTask: 'Delete Task',
  deleteTaskConfirmationMessage: 'Are you sure you want to delete this task?',
  deleteTaskRepeatsConfirmationMessage:
    'This is repeating task. You can delete only this or the repeating future tasks as well',
  deleteOnlyThisTask: 'Delete only this task',
  deleteRepeatingTask: 'Delete repeating task',
  yes: 'Yes',
  no: 'No',
  pleaseWaitAFewSeconds: 'Please wait a few seconds',
  deleting: 'Deleting',
  lostAndFound: 'Lost and found',
  current: 'Current',
  archive: 'Archive',
  edit: 'Edit',
  markAsResolved: 'Mark as resolved',
  markAsUnresolved: 'Mark as unresolved',
  add: 'Add',
  area: 'Area',
  addTask: 'Add task',
  editTask: 'Edit task',
  seeTaskDetails: 'See task details',
  savePicturesAndStartCleaning: 'Save and start cleaning',
  savePictures: 'Save pictures',
  takePhoto: 'Take photo',
  addArea: 'Add area',
  addAreaDescription: 'Add an area of the hotel for this task',
  selectProperty: 'Select property',
  selectRooms: 'Select rooms',
  selectTeam: 'Select team',
  selectDueDate: 'Select date',
  hskTeam: 'HSK Team',
  save: 'Save',
  actionIsRequired: `Action is required. Delete title and select an action from the list.`,
  noActionSelected: `No action selected`,
  changeSearchAction: `Try changing your search term`,
  noActionFound: `No action found`,
  selectActionHelp: `Can't find an action? Please contact Operations team so they can request it.`,
  writeToSelectAction: 'Write to select an action',
  writeTask: 'Write task',
  repetition: 'Repetition',
  noRepetition: 'No Repetition',
  repetitionTime: 'Repetition time',
  doesNotRepeat: 'Does not repeat',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  repeatOn: 'Repeat on',
  repeatsEvery: 'Repeats every',
  description: 'Description',
  addDescription: 'Add description',
  title: 'Title',
  addTitle: 'Add title',
  titleIsRequired: 'Title is required',
  titleMaxLength: 'Title should be less than {{maxLength}} chars',
  teamIsRequired: 'Team is required',
  openTasks: 'Open tasks',
  arrival: 'Arrival',
  departure: 'Departure',
  checkIn: 'Check In',
  times: 'Times',
  guests: 'Guests',
  name: 'Name',
  additionalInfo: 'Additional Info',
  addLostAndFound: 'Add lost and found',
  addDamageReport: 'Add damage report',
  unitIsRequired: 'Unit is required',
  addItem: 'Add item',
  editItem: 'Edit item',
  startCleaning: 'Start cleaning',
  endCleaning: 'End cleaning',
  endCleaningMessage: 'There is 1 open task. Are you sure you want to finish cleaning?',
  endCleaningMessage_plural: 'There are {{count}} open tasks. Are you sure you want to finish cleaning?',
  cancelAndViewTasks: 'Cancel and view tasks',
  ignoreAndMarkAsReadyToInspect: 'Ignore and mark as ready to inspect',
  ignoreAndMarkAsCleaned: 'Ignore and mark as cleaned',
  damageReports: 'Damage reports',
  confirm: 'Confirm',
  selectRoomOrArea: 'Select room/area or ',
  createNewOne: 'create new one',
  today: 'Today',
  tomorrow: 'Tomorrow',
  noTasksMessage: 'No task has been added',
  cleaningStatus: 'Cleaning',
  logOut: 'Log out',
  language: 'Language',
  selectLanguage: 'Select Language',
  isStandardized: 'Is Standardized?',
  selectStandardizedTask: 'Select standardized task',
  overdue: 'Overdue',
  arrivals: 'Arrivals',
  memberArrivals: 'Member arrivals',
  departures: 'Departures',
  stayovers: 'Stayovers',
  occupancy: 'OTB occupancy',
  midstayCleaning: 'Midstay cleaning',
  roomsToSell: 'Rooms to sell',
  editImages: 'Edit',
  toClean: 'to clean',
  toInspect: 'to inspect',
  noRoomsForCleaningToday: 'No rooms for cleaning today',
  noShow: 'No show',
  noShowReportConfirmationMessage: 'Please confirm that room number {{number}} is no show.',
  noShowReportQuestion: 'Did the guest check in yesterday?',
  checkInReportConfirmationMessage: 'Please confirm that the guest checked in to the room number {{number}}.',
  noResultsSearchMessage: 'No results match your search criteria',
  cancel: 'Cancel',
  selectCleaner: 'Choose cleaner',
  selectAll: 'Select All',
  floor: 'Floor',
  notAssigned: 'Not assigned',
  assignXRooms: 'Assign {{total}} rooms',
  assignRooms: 'Assign rooms',
  approvedLCOs: 'Approved LCOs',
  cleaner: 'Cleaner',
  roomsCleanForToday: 'All rooms are clean for today!🎉🎉',
  viewDamageReports: 'View damage reports',
  noTasks: 'No tasks',
  noDamageReports: 'No damage reports',
  dailyCleaningReport: 'Daily Cleaning Report',
  stayover: 'Stayover',
  roomMoveFrom: 'Room move from {{value}}',
  roomMoveTo: 'Room move to {{value}}',
  unassignAll: 'Unassign {{total}} rooms',
  unassignRoomsConfirmationMessage: 'Are you sure you would like to unassign {{total}} rooms?',
  unassignRoomsTitle: 'Unassign rooms',
  noRoomAssigned: 'No room assigned',
  hideActions: 'Hide actions',
  showActions: 'Show actions',
  action: 'Action',
  disableActionTitleModal: 'Reset the {{name}} filter',
  disableActionDescriptionModal: 'To use the {{currentFilter}} filter, please reset the {{resetFilter}} filter.',
  actionsSelected: '{{total}} action(s) selected',
  actionRequired: 'Action required',
  priority: 'Priority',
  lcoUntil: 'LCO Until',
  created: 'Created',
  startNow: 'Start now',
  scheduleTime: 'Schedule time',
  viewInfo: 'View info',
  reminder: 'Reminder',
  reminderTaskNotificationText: 'Your scheduled task is due today',
  inProgress: 'In progress',
  scheduled: 'Scheduled',
  moveMidstayCleaning: 'Move Stayover Cleaning?',
  moveMidstayTitle: 'Move Midstay',
  moveMidstayDescription: 'Are you sure you would like to move the midstay cleaning to {{date}}?',
  moveMidstayNotPossible: 'Sorry! The midstay cleaning for this room cannot be moved to another day!',
  moveMidstayNotification: 'Stayover cleaning has been successfully moved to {{date}}.',
  pleaseContactOps: 'Please contact Operations team',
  midCleanMovedTo: 'Stayover cleaning moved to: {{value}}',
  optional: 'Optional',
  hskDelay: 'HSK delay',
  hskDelays: 'HSK delays',
  hskDelayMessage: 'Update check in time for room {{number}}',
  notStarted: 'Not started',
  resolved: 'Resolved',
  noDamageReportsToday: 'No damage reports for today',
  yourTask: 'Your task',
  yourTaskGX: 'Your task from GX',
  damageReportedByGX: 'Damage Reported by GX',
  stay: 'Stay',
  checkOut: 'Check Out',
  noPermission: "You don't have permission, please contract support",
  approvedCheckoutTime: 'Approved checkout time',
  requestedCheckoutTime: 'Requested checkout time',
  checkinTime: 'Checkin time',
  commentForHousekeeping: 'Comment for housekeeping',
  numberOfApprovedLCOs: 'Number of approved LCOs',
  notSet: 'Not set',
  vip: 'VIP',
  extraService: 'Extra service',
  reservationMissing: 'Reservation is most likely cancelled, switch to General tab instead',
  confirmFor: 'Confirm For {{date}}',
  confirmAnotherDay: 'Confirm Another Day',
  taskConfirmed: 'You have confirmed the task for {{date}}',
  confirmYourTask: 'Confirm your task',
  confirmYourTaskGX: 'Confirm your task from GX',
  confirmHighPriorityTaskMessage:
    "Do you really want to set the due date to today? It's likely the task won't be confirmed and completed today.",
  setTomorrow: 'Set tomorrow',
  keepToday: 'Keep today',
  createTask: 'Create task',
  togglePushNotificationsLabel: 'Push notifications for iOS',
  confirmationNeeded: 'Confirmation needed',
  pictureTooBlurry: `The picture is too blurry. Please take a clear photo and upload it.`,
  tryAgain: `Try again`,
};
