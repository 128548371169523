import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Sortir les poubelles',
  [StandardizedTask.SMOKE]: 'La chambre sent la fumée',
  [StandardizedTask.SOFA_1]: 'Préparer le canapé pour 1 PAX',
  [StandardizedTask.SOFA_2]: 'Préparer le canapé pour 2 PAX',
  [StandardizedTask.BABY_BED]: 'Apportez un lit bébé',
  [StandardizedTask.DRYING_RACK]: 'Apportez un séchoir',
  [StandardizedTask.TOWELS]: 'Amène des serviettes',
  [StandardizedTask.IRON_AND_BOARD]: 'Apportez fer et planche',
  [StandardizedTask.TOILET_PAPER]: 'Apportez du papier toilette',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Check no-show à partir de 10h',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Salle de fête - vérifier les dommages',
  [StandardizedTask.LCO_11_30]: 'LCO à 11h30',
  [StandardizedTask.LCO_12]: 'LCO à 12h',
  [StandardizedTask.QUARANTINE]: "QUARANTAINE - HSK n'entre pas dans la pièce",
  [StandardizedTask.COFFEE_PODS]: 'Apportez des dosettes de café supplémentaires',
  [StandardizedTask.KITCHEN_TOWEL]: 'Apportez un torchon de cuisine',
  [StandardizedTask.BLANKET]: 'Apportez une couverture',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Check for mail',
  [StandardizedTask.BABY_CHAIR]: 'Apportez une chaise bébé',
  [StandardizedTask.TURN_HEATING_ON]: 'Turn heating on before CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Apportez un traitement désolé',
  [StandardizedTask.REFILL_HSK_HUB]: 'Recharge moyeu HSK',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Apportez un ventilateur dans la chambre',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: "Ne pas déranger l'invité",
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Privilégier le nettoyage des chambres',
  [StandardizedTask.SHAMPOO_REFILL]: 'Recharge de shampoing',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Recharge gel douche',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Recharge lotion pour le corps',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'Apporter serviette de cuisine',
  [StandardizedTask.BRING_FRYING_PAN]: 'Apporter poêle à frire',
  [StandardizedTask.BRING_COOKING_POT]: 'Apporter marmite',
  [StandardizedTask.BRING_WATER_GLASS]: "Apporter verre d'eau",
  [StandardizedTask.BRING_WINE_GLASS]: 'Apporter verre de vin',
  [StandardizedTask.BRING_CUP]: 'Apporter une tasse',
  [StandardizedTask.BRING_PLATES]: 'Apporter des assietes',
  [StandardizedTask.BRING_BOWL]: 'Apporter un bol',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'Apporter papier essuie-tout',
  [StandardizedTask.CLEAN_ELEVATOR]: 'Nettoyer ascenseur',
  [StandardizedTask.CLEAN_ENTRANCE]: 'Nettoyer entrée',
  [StandardizedTask.CLEAN_HALLWAYS]: 'Nettoyer couloir',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'Nettoyer buanderie',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'Nettoyer casiers pour valises',
  [StandardizedTask.CLEAN_STAIRS]: 'Nettoyer les escaliers',
  [StandardizedTask.CLEAN_TOILET]: 'Nettoyer WC',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'Nettoyer WC Publiques',
  [StandardizedTask.CLEAN_TERRACE]: 'Nettoyer terrasse',
  [StandardizedTask.CLEAN_BALCLONY]: 'Nettoyer balcon',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'Nettoyer lave-linge et séchoir à linge',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'Revérifier chambre',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'Vérifier date de livraison du linge',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'Vérifier péremption des produits de consommation',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'Vérifier et réinitialiser casier pour valises',
  [StandardizedTask.CHECK_MAILBOX]: 'Vérifier le courrier  (lettres pour les clients?)',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'Remplir Essential Closet',
  [StandardizedTask.REFILL_DETERGENT]: 'Remplir détergent lessive',
  [StandardizedTask.ORGANIZE_STORAGE]: 'Organiser pièce de stockage',
  [StandardizedTask.ORDER_CRISPS]: 'Passer une commande de chips',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Passer une commande de jus / boisson non-alcoolisée',
  [StandardizedTask.ORDER_BEER]: 'Passer une commande de bière',
  [StandardizedTask.ORDER_WATER]: "Passer une commande d'eau",
  [StandardizedTask.ORDER_EARPLUGS]: "Passer une commande de bouchons d'oreilles",
  [StandardizedTask.ORDER_SWEETS]: 'Passer une commande bonbons',
  [StandardizedTask.ORDER_COFFEE]: 'Passer une commande de café / Grind',
  [StandardizedTask.ORDER_TEA]: 'Passer une commande de thé / Hoogly',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'Sortir le linge sale',
  [StandardizedTask.WATER_FLOWERS]: 'Arroser les plantes',
  [StandardizedTask.WATER_GARDEN]: 'Arroser le jardin',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilateur de salle de bain bruyant',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'La lumière ne fonctionne pas',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Le lavabo est bouché',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: "L'évacuation de la douche est bloquée",
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtre de salle de bain',
  [StandardizedDamageReport.SINK]: 'Évier',
  [StandardizedDamageReport.TOILET]: 'Toilette',
  [StandardizedDamageReport.SHOWER]: 'Douche',
  [StandardizedDamageReport.STOVE]: 'Le fourneau',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Réfrigérateur/Minibar',
  [StandardizedDamageReport.DOOR]: 'Porte',
  [StandardizedDamageReport.WINDOW]: 'Fenêtre',
  [StandardizedDamageReport.AC]: 'CA',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Ampoule',
  [StandardizedDamageReport.CHAIRS]: 'Chaises',
  [StandardizedDamageReport.HEATING]: 'Chauffage',
  [StandardizedDamageReport.TV]: 'la télé',
  [StandardizedDamageReport.TABLES]: 'les tables',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Équipe HSK',
  [Team.MAINTENANCE]: 'Équipe de maintenance',
  [Team.OPERATIONS]: 'Équipe des opérations',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Cuisine',
  [DamageReportArea.BED]: 'Lit',
  [DamageReportArea.BATHROOM]: 'Salle de bains',
  [DamageReportArea.SEATING_AREA]: 'Zone assise',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Murs/Plafond/Sol',
  [DamageReportArea.ICT]: 'TIC',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Nettoyer',
  [DamageReportActionRequired.REPLACE]: 'Remplacer',
  [DamageReportActionRequired.REPAIR]: 'Réparation',
  [DamageReportActionRequired.CHECK]: 'Vérifier',
  [DamageReportActionRequired.OTHER]: 'Autre',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'Haute',
};

const popupNotifications = {
  taskDeletionNotification: 'La tâche a été supprimée avec succès',
  lostAndFoundDeletionNotification: "L'objet perdu et trouvé a été supprimé avec succès",
  damageReportDeletionNotification: 'Le rapport de dommages a été supprimé avec succès',
  taskCreationNotification: 'La tâche a été créée avec succès',
  lostAndFoundCreationNotification: "L'objet perdu et trouvé a été créé avec succès",
  damageReportCreationNotification: 'Le rapport de dommages a été créé avec succès',
  taskCompletionNotification: 'La tâche a été accomplie avec succès',
  lostAndFoundCompletionNotification: "L'objet perdu et trouvé a été résolu avec succès",
  damageReportCompletionNotification: 'Le rapport de dommages a été résolu avec succès',
  taskUpdateNotification: 'La tâche a été mise à jour avec succès',
  lostAndFoundUpdateNotification: "L'objet perdu et trouvé a été mis à jour avec succès",
  damageReportUpdateNotification: 'Le rapport de dommages a été mis à jour avec succès',
  pleaseSelectRoomsNotification: 'Veuillez sélectionner des chambres',
  noShowReportNotification: 'Aucune émission signalée avec succès pour la salle {{ number }}',
  checkInReportNotification: "L'enregistrement a bien été signalé pour la chambre {{number}}",
  damageReportWorkingTimeScheduledNotification: 'Rapport de dommages planifié avec succès pour {{date}}',
  hskDelayNotification: "Délai HSK réussi pour la salle {{number}}. L'heure d'arrivée est mise à jour à {{time}}",
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Plus de filtres',
  resetAllFilters: 'Réinitialiser tous les filtres',
  reset: 'Réinitialiser',
  cleaned: 'Nettoyé',
  allRoomsCleaned: `Toutes les chambres nettoyées`,
  dirty: 'Sale',
  readyToClean: 'Prêt à nettoyer',
  stayoverReadyToClean: 'Stayover - Prêt à nettoyer',
  occupiedCleaningToday: "Occupé - Nettoyage aujourd'hui",
  readyToInspect: 'Prêt à inspecter',
  stayoverInspection: 'Inspection de séjour',
  vacant: 'Vacant',
  occupied: 'Occupé',
  clean: 'Nettoyer',
  daily: 'du quotidien',
  weekly: 'Hebdomadaire',
  biweekly: 'Bihebdomadaire',
  monthly: 'Mensuel',
  none: 'Aucun',
  tasks: 'Tâches',
  notifications: 'Avis',
  more: 'Suite',
  select: 'Sélectionner',
  property: 'Propriété',
  result: 'Résultat',
  results: 'Résultats',
  properties: 'Propriétés',
  rooms: 'Pièces',
  team: 'Équipe',
  dueDate: "Date d'échéance",
  date: 'Date',
  close: 'proche',
  search: 'Chercher',
  login: 'Connexion',
  loading: 'Chargement...',
  anErrorHasOccurred: 'Une erreur est survenue',
  chooseDate: 'Choisissez la date',
  selectDate: 'Sélectionner une date',
  selectADate: 'Sélectionnez une date',
  markAsClean: 'Marquer comme propre',
  markAsReadyToInspect: 'Marquer comme prêt à inspecter',
  viewTasks: 'Afficher les tâches',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Registre de la chambre`,
  taskLog: `Registre de la tâche`,
  seeDetails: 'Voir les détails',
  assignedTasks: 'Des tâches attribuées',
  delete: 'Effacer',
  deleteTask: 'Supprimer la tâche',
  deleteTaskConfirmationMessage: 'Voulez-vous vraiment supprimer cette tâche?',
  deleteTaskRepeatsConfirmationMessage:
    "C'est une tâche répétitive. Vous pouvez supprimer uniquement cette tâche ou les tâches futures répétitives également",
  deleteOnlyThisTask: 'Supprimer uniquement cette tâche',
  deleteRepeatingTask: 'Supprimer la tâche répétitive',
  yes: 'Oui',
  no: 'Non',
  pleaseWaitAFewSeconds: 'Veuillez patienter quelques secondes',
  deleting: 'Suppression',
  lostAndFound: 'OBJET TROUVÉ',
  current: 'Courant',
  archive: 'Archive',
  edit: 'Éditer',
  markAsResolved: 'Marquer comme résolu',
  markAsUnresolved: 'Marquer comme non résolu',
  add: 'Ajouter',
  area: 'Zone',
  addTask: 'Ajouter une tâche',
  editTask: 'Modifier la tâche',
  seeTaskDetails: 'Voir la tâche',
  savePicturesAndStartCleaning: 'Enregistrer et commencer le nettoyage',
  savePictures: 'Enregistrer les photos',
  takePhoto: 'Prendre une photo',
  addArea: 'Ajouter une zone',
  addAreaDescription: "Ajouter une zone de l'hôtel pour cette tâche",
  selectProperty: 'Sélectionnez la propriété',
  selectRooms: 'Sélectionnez des chambres',
  selectTeam: 'Choisis une équipe',
  selectDueDate: 'Sélectionner une date',
  hskTeam: 'Équipe HSK',
  save: 'sauvegarder',
  actionIsRequired: `L'action est requise. Supprimez le titre et sélectionnez une action dans la liste.`,
  noActionSelected: `Aucune action sélectionnée`,
  changeSearchAction: `Essayez de changer votre terme de recherche`,
  noActionFound: `Aucune action trouvée`,
  selectActionHelp: `Impossible de trouver une action ? Veuillez contacter l'équipe des opérations afin qu'ils puissent la demander.`,
  writeToSelectAction: 'Écrire pour sélectionner une action',
  writeTask: "Tâche d'écriture",
  repetition: 'Répétition',
  noRepetition: 'Pas de répétition',
  repetitionTime: 'Temps de répétition',
  doesNotRepeat: 'Ne se répète pas',
  day: 'Jour',
  week: 'La semaine',
  month: 'Mois',
  repeatOn: 'Répéter sur',
  repeatsEvery: 'Se répète tous les',
  description: 'La description',
  title: 'Titre',
  addTitle: 'Ajouter un titre',
  titleIsRequired: 'Titre requis',
  titleMaxLength: 'Le titre doit comporter moins de {{maxLength}} caractères.',
  teamIsRequired: "L'équipe est requise",
  openTasks: 'Tâches ouvertes',
  arrival: 'Arrivée',
  departure: 'Départ',
  checkIn: 'Enregistrement',
  times: 'Fois',
  guests: 'Invités',
  name: 'Nom',
  additionalInfo: 'Information additionnelle',
  addLostAndFound: 'Ajouter perdu et trouvé',
  addDamageReport: 'Ajouter un rapport de dommages',
  unitIsRequired: "L'unité est requise",
  addItem: 'Ajouter un item',
  editItem: "Modifier l'article",
  startCleaning: 'Commence a nettoyer',
  endCleaning: 'Fin de ménage',
  endCleaningMessage: 'Il y a 1 tâche ouverte. Voulez-vous vraiment terminer le nettoyage?',
  endCleaningMessage_plural: 'Il y a {{count}} tâches ouvertes. Voulez-vous vraiment terminer le nettoyage?',
  cancelAndViewTasks: 'Annuler et afficher les tâches',
  ignoreAndMarkAsReadyToInspect: 'Ignorer et marquer comme prêt à inspecter',
  ignoreAndMarkAsCleaned: 'Ignorer et marquer comme nettoyé',
  damageReports: 'Rapports de dommages',
  confirm: 'Confirmer',
  selectRoomOrArea: 'Sélectionnez la pièce/la zone ou',
  createNewOne: 'créer un nouveau',
  today: "Aujourd'hui",
  tomorrow: 'Demain',
  noTasksMessage: "Aucune tâche n'a été ajoutée",
  cleaningStatus: 'Nettoyage',
  logOut: 'Se déconnecter',
  language: 'Langue',
  selectLanguage: 'Choisir la langue',
  isStandardized: 'est standardisé ?',
  selectStandardizedTask: 'Sélectionnez une tâche standardisée',
  overdue: 'En retard',
  arrivals: 'Arrivées',
  memberArrivals: 'Member arrivals',
  departures: 'départs',
  stayovers: 'Séjour',
  occupancy: 'Occupation OTB',
  midstayCleaning: 'Ménage en milieu de séjour',
  roomsToSell: 'Chambres à vendre',
  editImages: 'Modifier les images',
  toClean: 'nettoyer',
  toInspect: 'inspecter',
  noRoomsForCleaningToday: "Pas de chambres à nettoyer aujourd'hui",
  noShow: 'Pas de spectacle',
  noShowReportConfirmationMessage: "Veuillez confirmer que la chambre numéro {{number}} n'est pas présentée.",
  noShowReportQuestion: "L'invité s'est-il enregistré hier ?",
  checkInReportConfirmationMessage:
    "Veuillez confirmer que le client s'est enregistré dans la chambre numéro {{number}}.",
  noResultsSearchMessage: 'Aucun résultat ne correspond à vos critères de recherche',
  cancel: 'Annuler',
  selectCleaner: 'Choisissez le nettoyant',
  selectAll: 'Tout sélectionner',
  floor: 'Sol',
  notAssigned: 'Non attribué',
  assignXRooms: 'Attribuez {{total}} chambres',
  assignRooms: 'Attribuer des chambres',
  approvedLCOs: 'LCO approuvés',
  cleaner: 'Nettoyeur',
  roomsCleanForToday: "Toutes les chambres sont propres pour aujourd'hui !🎉🎉",
  viewDamageReports: 'Afficher les rapports de dommages',
  noTasks: 'Aucune tâche',
  noDamageReports: 'Aucun rapport de dommages',
  dailyCleaningReport: 'Rapport de nettoyage quotidien',
  stayover: 'Séjour',
  roomMoveFrom: 'Déménagement de salle de {{value}}',
  roomMoveTo: 'Déménagement de la salle à {{value}}',
  unassignAll: 'Désattribuer tout',
  unassignRoomsConfirmationMessage: "Voulez-vous vraiment annuler l'attribution de {{total}} chambres ?",
  unassignRoomsTitle: 'Désattribuer des salles',
  noRoomAssigned: 'Aucune chambre attribuée',
  hideActions: 'Actions de dissimulation',
  showActions: `Actions d'affichage`,
  action: 'Action',
  disableActionTitleModal: 'Réinitialiser le filtre {{name}}.',
  disableActionDescriptionModal:
    'Pour utiliser le filtre {{currentFilter}}, veuillez réinitialiser le filtre {{resetFilter}}.',
  actionsSelected: '{{total}} action(s) sélectionnée(s)',
  actionRequired: 'Action requise',
  priority: 'Priorité',
  lcoUntil: "LCO jusqu'au",
  created: 'Établi',
  startNow: 'Commencez maintenant',
  scheduleTime: 'Horaire',
  viewInfo: 'Afficher les informations',
  reminder: 'Rappel',
  reminderTaskNotificationText: "Votre tâche planifiée est due aujourd'hui",
  inProgress: 'En cours',
  scheduled: 'Programmé',
  moveMidstayCleaning: 'Déplacer le nettoyage de séjour ?',
  moveMidstayTitle: 'Déménager en milieu de séjour',
  moveMidstayDescription: 'Êtes-vous sûr de vouloir déplacer le ménage de mi-journée au {{date}} ?',
  moveMidstayNotPossible:
    'Pardon! Le ménage de mi-journée pour cette chambre ne peut pas être reporté à un autre jour !',
  moveMidstayNotification: 'Le nettoyage de séjour a été déplacé avec succès vers {{date}}.',
  pleaseContactOps: "Veuillez contacter l'équipe des opérations",
  midCleanMovedTo: 'Ménage de séjour déplacé vers : {{value}}',
  optional: 'optionnel',
  hskDelay: 'Délai HSK',
  hskDelays: 'Retards HSK',
  hskDelayMessage: "Mettre à jour l'heure d'arrivée pour la chambre {{number}}",
  notStarted: 'Pas commencé',
  resolved: 'Résolu',
  noDamageReportsToday: "Aucun rapport de dommages pour aujourd'hui",
  yourTask: 'Ta tâche',
  yourTaskGX: 'Votre tâche de GX',
  damageReportedByGX: 'Dommages signalés par GX',
  stay: 'Dates de séjour',
  checkOut: 'Heure de départ',
  noPermission: "Vous n'avez pas la permission, veuillez contacter le support.",
  approvedCheckoutTime: 'Heure de départ approuvée',
  requestedCheckoutTime: 'Heure de départ demandée',
  checkinTime: "Heure d'enregistrement",
  commentForHousekeeping: "Commentaire pour le service d'entretien ménager",
  numberOfApprovedLCOs: 'Nombre de LCOs approuvés',
  notSet: 'Non défini',
  vip: 'VIP',
  extraService: 'Service supplémentaire',
  reservationMissing: "La réservation est très probablement annulée, passez plutôt à l'onglet Général.",
  confirmFor: 'Confirmer pour le {{date}}',
  confirmAnotherDay: 'Confirmer une autre date',
  taskConfirmed: 'Vous avez confirmé la tâche pour le {{date}}.',
  confirmYourTask: 'Confirmez votre tâche',
  confirmYourTaskGX: 'Confirmez votre tâche depuis GX',
  confirmHighPriorityTaskMessage:
    "Voulez-vous vraiment fixer la date à aujourd'hui ? Il est probable que la tâche ne soit pas confirmée et terminée aujourd'hui.",
  setTomorrow: 'Changer pour demain',
  keepToday: "Garder aujourd'hui",
  createTask: 'Créer une tâche',
  confirmationNeeded: 'Confirmation nécessaire',
  pictureTooBlurry: `L'image est trop floue. Veuillez prendre une photo nette et la télécharger.`,
  tryAgain: `Essayer de nouveau`,
};
