import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Izbacite smeće',
  [StandardizedTask.SMOKE]: 'Soba smrdi na dim',
  [StandardizedTask.SOFA_1]: 'Pripremite sofu za 1 osobu',
  [StandardizedTask.SOFA_2]: 'Pripremite sofu za 2 osobe',
  [StandardizedTask.BABY_BED]: 'Donesite dječji krevet',
  [StandardizedTask.DRYING_RACK]: 'Donesite stalak za sušenje veša',
  [StandardizedTask.TOWELS]: 'Donesite ručnike',
  [StandardizedTask.IRON_AND_BOARD]: 'Donesite peglu i dasku za peglanje',
  [StandardizedTask.TOILET_PAPER]: 'Donesite toaletni papir',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Provjerite no-show',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Party soba - provjerite ima li oštećenja',
  [StandardizedTask.LCO_11_30]: 'Late check out - Kasna odjava gostiju u 11:30',
  [StandardizedTask.LCO_12]: 'Late check out - Kasna odjava gostiju u 12:00',
  [StandardizedTask.QUARANTINE]: 'KARANTIN - HSK tim, ne ulazite u sobu',
  [StandardizedTask.COFFEE_PODS]: 'Donesite dodatne kapsule kafe',
  [StandardizedTask.KITCHEN_TOWEL]: 'Donesite kuhinjski peškir',
  [StandardizedTask.BLANKET]: 'Donesite ćebe',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Provjerite ima li pošte',
  [StandardizedTask.BABY_CHAIR]: 'Ponesite stolicu za bebu',
  [StandardizedTask.TURN_HEATING_ON]: 'Uključite grijanje prije CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Donesi oprost tretman',
  [StandardizedTask.REFILL_HSK_HUB]: 'Napunite HSK čvorište',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Donesite ventilator u sobu',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: 'Ne ometajte goste',
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Dajte prioritet čišćenju sobe',
  [StandardizedTask.SHAMPOO_REFILL]: 'Nadopuna šampona',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Nadopuna gela za tuširanje',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Punjenje losiona za tijelo',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'donijeti kuhinjske krpe',
  [StandardizedTask.BRING_FRYING_PAN]: 'donijeti tavu',
  [StandardizedTask.BRING_COOKING_POT]: 'donijeti lonac',
  [StandardizedTask.BRING_WATER_GLASS]: 'donijeti čašu / čaše za vodu',
  [StandardizedTask.BRING_WINE_GLASS]: 'donijeti čašu / čaše za vino',
  [StandardizedTask.BRING_CUP]: 'donijeti šalicu',
  [StandardizedTask.BRING_PLATES]: 'donijeti tanjure',
  [StandardizedTask.BRING_BOWL]: 'donijeti zdjelu',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'donijeti papirnate ručnike',
  [StandardizedTask.CLEAN_ELEVATOR]: 'očistiti lift',
  [StandardizedTask.CLEAN_ENTRANCE]: 'očistiti ulaz',
  [StandardizedTask.CLEAN_HALLWAYS]: 'očistiti hodnik',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'očistiti praonicu rublja',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'očistiti ormariće za prtljagu',
  [StandardizedTask.CLEAN_STAIRS]: 'očistiti stepenice',
  [StandardizedTask.CLEAN_TOILET]: 'očistiti zahod',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'očistiti javni zahod',
  [StandardizedTask.CLEAN_TERRACE]: 'očistiti terasu',
  [StandardizedTask.CLEAN_BALCLONY]: 'očistiti balkon',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'očistiti perilicu i sušilicu rublja',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'provjeriti sobu',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'provjeriti dostavu rublja',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'provjeriti datum isteka hrane i pića',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'provjeriti i resetirati ormariće za prtljagu',
  [StandardizedTask.CHECK_MAILBOX]: 'provjeriti poštanski sandučić  (nova pošta za goste?)',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'nadopuniti osnovne stvari',
  [StandardizedTask.REFILL_DETERGENT]: 'nadopuniti deterdžent',
  [StandardizedTask.ORGANIZE_STORAGE]: 'očistiti skladište',
  [StandardizedTask.ORDER_CRISPS]: 'Naručiti čips',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Naručiti sok/bezalkoholno pića',
  [StandardizedTask.ORDER_BEER]: 'Naručiti pivo',
  [StandardizedTask.ORDER_WATER]: 'Naručiti vodu',
  [StandardizedTask.ORDER_EARPLUGS]: 'Naručiti čepiće za uši',
  [StandardizedTask.ORDER_SWEETS]: 'Naručiti slatkiše',
  [StandardizedTask.ORDER_COFFEE]: 'Naručiti Grind kafu',
  [StandardizedTask.ORDER_TEA]: 'Naručiti Hoogle čaj',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'iznijeti prljavo rublje',
  [StandardizedTask.WATER_FLOWERS]: 'zaliti cvijeće',
  [StandardizedTask.WATER_GARDEN]: 'zaliti vrt',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilator u kupaonici glasan',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Svjetlo ne radi',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Sudopera je blokirana',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Odvod tuša je blokiran',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filter za kupaonicu',
  [StandardizedDamageReport.SINK]: 'Umivaonik',
  [StandardizedDamageReport.TOILET]: 'WC',
  [StandardizedDamageReport.SHOWER]: 'Tuš',
  [StandardizedDamageReport.STOVE]: 'Štednjak',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Hladnjak/minibar',
  [StandardizedDamageReport.DOOR]: 'Vrata',
  [StandardizedDamageReport.WINDOW]: 'Prozor',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'WiFi',
  [StandardizedDamageReport.LIGHTBULB]: 'Žarulja',
  [StandardizedDamageReport.CHAIRS]: 'Stolice',
  [StandardizedDamageReport.HEATING]: 'Grijanje',
  [StandardizedDamageReport.TV]: 'Televizor',
  [StandardizedDamageReport.TABLES]: 'Stolovi',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'HSK-tim',
  [Team.MAINTENANCE]: 'Tim za održavanje',
  [Team.OPERATIONS]: 'Operativni tim',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kuhinja',
  [DamageReportArea.BED]: 'Krevet',
  [DamageReportArea.BATHROOM]: 'Kupaonica',
  [DamageReportArea.SEATING_AREA]: 'Prostor za sjedenje',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Zidovi/Strop/Pod',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Očistiti',
  [DamageReportActionRequired.REPLACE]: 'Zamijeniti',
  [DamageReportActionRequired.REPAIR]: 'Popraviti',
  [DamageReportActionRequired.CHECK]: 'Provjeriti',
  [DamageReportActionRequired.OTHER]: 'Ostalo',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standardan',
  [TaskPriority.HIGH]: 'Visok',
};

const popupNotifications = {
  taskDeletionNotification: 'Zadatak je uspješno izbrisan',
  lostAndFoundDeletionNotification: 'Izgubljena i pronađena stavka uspješno je izbrisana',
  damageReportDeletionNotification: 'Izvještaj o šteti uspješno je izbrisan',
  taskCreationNotification: 'Zadatak je uspješno kreiran',
  lostAndFoundCreationNotification: 'Izgubljena i pronađena stavka uspješno je kreirana',
  damageReportCreationNotification: 'Izvješće o šteti uspješno je kreirano',
  taskCompletionNotification: 'Zadatak je uspješno završen',
  lostAndFoundCompletionNotification: 'Izgubljena i pronađena stavka uspješno je riješena',
  damageReportCompletionNotification: 'Prijava štete uspješno je riješena',
  taskUpdateNotification: 'Zadatak je uspješno ažuriran',
  lostAndFoundUpdateNotification: 'Izgubljena i pronađena stavka uspješno je ažurirana',
  damageReportUpdateNotification: 'Izvješće o šteti uspješno je ažurirano',
  pleaseSelectRoomsNotification: 'Odaberite sobe',
  noShowReportNotification: 'No show uspješno prijavljen za sobu broj {{number}}',
  checkInReportNotification: 'Check in uspješno prijavljen za sobu {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Izvještaj o šteti zakazan za {{date}}',
  hskDelayNotification: 'Uspješno HSK odlaganje za sobu {{number}}. Check in pomeren za {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Više filtera',
  resetAllFilters: 'Ponovno postavite sve filtre',
  reset: 'Ponovno postavi',
  cleaned: 'Očišćeno',
  allRoomsCleaned: `Sve sobe očišćene`,
  dirty: 'Prljavo',
  readyToClean: 'Soba spremna za čišćenje',
  stayoverReadyToClean: 'Stayover / Produženi boravak - spremno za čišćenje',
  occupiedCleaningToday: 'Soba je okupirana - čišćenje danas',
  readyToInspect: 'Soba očišćena - spremna za inspekciju',
  stayoverInspection: 'Stayover / Produženi boravak očišćen / Spreman za inspekciju',
  vacant: 'Slobodna soba',
  occupied: 'Zauzeta soba',
  clean: 'Čisto',
  daily: 'Dnevno',
  weekly: 'Tjedno',
  biweekly: 'Dva puta tjedno',
  monthly: 'Mjesečno',
  none: 'Nijedan/o',
  tasks: 'Zadaci',
  notifications: 'Obavijesti',
  more: 'Više',
  select: 'Odaberi',
  property: 'Hotel',
  result: 'Rezultat',
  results: 'Rezultati',
  properties: 'Hoteli',
  rooms: 'Sobe',
  team: 'Tim',
  dueDate: 'Krajnji datum završetka zadatka / aktivnosti',
  date: 'Datum',
  close: 'Zatvoriti',
  search: 'tražiti',
  login: 'Prijaviti se',
  loading: 'Učitava se...',
  anErrorHasOccurred: 'Došlo je do pogreške',
  chooseDate: 'Odaberite Datum',
  selectDate: 'Odaberite datum',
  selectADate: 'Odaberite datum',
  markAsClean: 'Označi kao čisto',
  markAsReadyToInspect: 'Označi kao spremno za pregled/inspekciju',
  viewTasks: 'Prikaz zadataka',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Dnevnik soba`,
  taskLog: `Zapisnik zadatka`,
  seeDetails: 'Pogledaj detalje',
  assignedTasks: 'Dodijeljeni zadaci',
  delete: 'Izbrisati',
  deleteTask: 'Izbriši zadatak',
  deleteTaskConfirmationMessage: 'Jeste li sigurni da želite izbrisati ovaj zadatak?',
  deleteTaskRepeatsConfirmationMessage:
    'Ovaj zadatak se ponavlja. Možete izbrisati samo ovaj ili ponavljajuće/duplicirane buduće zadatke',
  deleteOnlyThisTask: 'Izbrišite samo ovaj zadatak',
  deleteRepeatingTask: 'Izbrišite ponavljajući zadatak',
  yes: 'Da',
  no: 'Ne',
  pleaseWaitAFewSeconds: 'Pričekajte nekoliko sekundi',
  deleting: 'Brisanje',
  lostAndFound: 'Izgubljeno i nađeno',
  current: 'Trenutno',
  archive: 'Arhiva',
  edit: 'Urediti',
  markAsResolved: 'Označi kao riješeno/završeno',
  markAsUnresolved: 'Označi kao neriješeno/nezavršeno',
  add: 'Dodati',
  area: 'Područje',
  addTask: 'Dodajte zadatak',
  editTask: 'Uređivanje zadatka',
  seeTaskDetails: 'Vidi zadatak',
  savePicturesAndStartCleaning: 'Spremi i započni čišćenje',
  savePictures: 'Spremi slike',
  takePhoto: 'Snimi fotografiju',
  addArea: 'Dodajte područje',
  addAreaDescription: 'Dodajte područje / segment za ovaj zadatak',
  selectProperty: 'Odaberite hotel',
  selectRooms: 'Odaberite sobe',
  selectTeam: 'Odaberite tim',
  selectDueDate: 'Odaberite datum',
  hskTeam: 'HSK - tim',
  save: 'Uštedjeti',
  actionIsRequired: `Potrebna je akcija. Izbrišite naslov i odaberite akciju s popisa.`,
  noActionSelected: `Nije odabrana nikakva radnja`,
  changeSearchAction: `Pokušajte promijeniti pojam pretrage`,
  noActionFound: `Nema pronađene radnje`,
  selectActionHelp: `Ne možete pronaći radnju? Molimo kontaktirajte tim za operacije kako bi je mogli zatražiti.`,
  writeToSelectAction: 'Napišite za odabir radnje',
  writeTask: 'Napišite zadatak',
  repetition: 'Ponavljanje',
  noRepetition: 'Nema ponavljanja',
  repetitionTime: 'Vrijeme ponavljanja',
  doesNotRepeat: 'Ne ponavlja se',
  day: 'Dan',
  week: 'Tjedan',
  month: 'Mjesec',
  repeatOn: 'Ponovite na',
  repeatsEvery: 'Ponavlja se svaki',
  description: 'Opis',
  title: 'Naslov',
  addTitle: 'Dodaj naslov',
  titleIsRequired: 'Naslov je potreban',
  titleMaxLength: 'Naslov mora biti kraći od {{maxLength}} karaktera',
  teamIsRequired: 'Potreban je tim',
  openTasks: 'Otvoreni zadaci',
  arrival: 'Dolazak',
  departure: 'Odlazak',
  checkIn: 'Prijava',
  times: 'Vremena',
  guests: 'Gosti',
  name: 'Ime',
  additionalInfo: 'Dodatne informacije',
  addLostAndFound: 'Dodajte izgubljeno i pronađeno',
  addDamageReport: 'Dodajte izvještaj o šteti',
  unitIsRequired: 'Jedinica je potrebna',
  addItem: 'Dodaj Predmet',
  editItem: 'Uređivanje stavke',
  startCleaning: 'Počnite  čišćenje',
  endCleaning: 'Završite čišćenje',
  endCleaningMessage: 'Postoji 1 otvoren zadatak. Jeste li sigurni da želite završiti čišćenje?',
  endCleaningMessage_plural: 'Postoje {{count}} otvorena zadatka. Jeste li sigurni da želite završiti čišćenje?',
  cancelAndViewTasks: 'Otkažite i pregledajte zadatke',
  ignoreAndMarkAsReadyToInspect: 'Zanemari i označi kao spremno za pregled',
  ignoreAndMarkAsCleaned: 'Zanemari i označi kao očišćeno',
  damageReports: 'Izvještaji o šteti',
  confirm: 'Potvrditi',
  selectRoomOrArea: 'Odaberite sobu/područje ili',
  createNewOne: 'stvoriti novo',
  today: 'Danas',
  tomorrow: 'Sutra',
  noTasksMessage: 'Nije dodan nijedan zadatak',
  cleaningStatus: 'Čišćenje',
  logOut: 'Odjavite se',
  language: 'Jezik',
  selectLanguage: 'Izaberi jezik',
  isStandardized: 'Je li standardizirano?',
  selectStandardizedTask: 'Odaberite standardizirani zadatak',
  overdue: 'Zadatak nije završen / odgodjen. ',
  arrivals: 'Dolasci',
  memberArrivals: 'Member arrivals',
  departures: 'Odlasci',
  stayovers: 'Čišćenje u sredini boravka / na pola boravka',
  occupancy: 'Trenutna popunjenost hotela / okupiranost',
  midstayCleaning: 'Čišćenje u sredini boravka / na pola boravka',
  roomsToSell: 'Sobe za prodaju',
  editImages: 'Uređivanje slika',
  toClean: 'čistiti',
  toInspect: 'provjeriti / inspektirati',
  noRoomsForCleaningToday: 'Danas nema soba za čišćenje',
  noShow: 'Gost se nije pojavio (no show)',
  noShowReportConfirmationMessage: 'Potvrdite da li u sobi ima/nema gost (no show check)',
  noShowReportQuestion: 'Je li se gost jučer prijavio?',
  checkInReportConfirmationMessage: 'Molimo potvrdite da se gost prijavio u sobu broj {{number}}.',
  noResultsSearchMessage: 'Nijedan rezultat ne odgovara vašim kriterijima pretraživanja',
  cancel: 'Otkazati',
  selectCleaner: 'Odaberite čistača / čistačicu',
  selectAll: 'Odabrati sve',
  floor: 'Kat / Sprat',
  notAssigned: 'Nije dodijeljeno',
  assignXRooms: 'Dodijelite {{total}} soba',
  assignRooms: 'Dodijelite sobe',
  approvedLCOs: 'Approved LCOs',
  cleaner: 'Čisti',
  roomsCleanForToday: 'Sve sobe su očišćene! 🎉🎉',
  viewDamageReports: 'Pregledajte izvješća o šteti',
  noTasks: 'Nema zadataka',
  noDamageReports: 'Nema izvještaja o šteti',
  dailyCleaningReport: 'Dnevno izvješće o čišćenju',
  stayover: 'Ostanite',
  roomMoveFrom: 'Premještanje sobe iz {{value}}',
  roomMoveTo: 'Premještanje sobe na {{value}}',
  unassignAll: 'Poništi dodjelu svih',
  unassignRoomsConfirmationMessage: 'Jeste li sigurni da želite poništiti dodjelu soba za {{total}}?',
  unassignRoomsTitle: 'Poništi dodjelu soba',
  noRoomAssigned: 'Nema dodeljenih soba',
  action: 'Radnja',
  hideActions: 'Skrivanje radnji',
  showActions: 'Prikaži radnje',
  actionsSelected: '{{total}} akcija(e) odabrana(e)',
  disableActionTitleModal: 'Resetiraj filter {{name}}.',
  disableActionDescriptionModal: 'Za korištenje filtra {{currentFilter}}, molimo resetirajte filter {{resetFilter}}.',
  actionRequired: 'Potrebna radnja',
  priority: 'Prioritet',
  lcoUntil: 'LCO do',
  created: 'Stvoreno',
  startNow: 'Start now',
  scheduleTime: 'Schedule time',
  viewInfo: 'View info',
  reminder: 'Podsjetnik',
  reminderTaskNotificationText: 'Your scheduled task is due today',
  inProgress: 'In progress',
  scheduled: 'Scheduled',
  moveMidstayCleaning: 'Pomeri čišćenje?',
  moveMidstayTitle: 'Pomeri čišćenje',
  moveMidstayDescription: 'Da li ste sigurni da želite da pomerite čišćenje za {{date}}?',
  moveMidstayNotPossible: 'Nije moguće pomeriti čišćenje za drugi dan u ovoj sobi!',
  moveMidstayNotification: 'Uspešno ste pomerili čišćenje za {{date}}.',
  pleaseContactOps: 'Kontaktirajte Operativni tim za opciju pomeranja čišćenja',
  midCleanMovedTo: 'Čišćenje pomereno za: {{value}}',
  optional: 'Neobavezno',
  hskDelay: 'HSK odlaganje',
  hskDelays: 'HSK odlaganja',
  hskDelayMessage: 'Promeri check in za sobu {{number}}',
  notStarted: 'Nije počelo',
  resolved: 'Završeno',
  noDamageReportsToday: 'Nema izveštaja o šteti za danas',
  yourTask: 'Vaš zadatak',
  yourTaskGX: 'Vaš zadatak iz GX-a',
  damageReportedByGX: 'Oštećenje koje je prijavio GX',
  stay: 'Datumi boravka',
  checkOut: 'Vrijeme odjave',
  noPermission: 'Nemate dozvolu, molim kontaktirajte podršku.',
  approvedCheckoutTime: 'Odobreno vrijeme odjave',
  requestedCheckoutTime: 'Zatraženo vrijeme odjave',
  checkinTime: 'Vrijeme prijave',
  commentForHousekeeping: 'Komentar za tim za čišćenje',
  numberOfApprovedLCOs: 'Broj odobrenih LCO-a',
  notSet: 'Nije postavljeno',
  vip: 'VIP',
  extraService: 'Dodatna usluga',
  reservationMissing: 'Rezervacija je najvjerojatnije otkazana, prebacite se na karticu "Općenito" umjesto toga.',
  confirmFor: 'Potvrdi za {{date}}',
  confirmAnotherDay: 'Potvrdi za idući dan',
  taskConfirmed: 'Potvrdili ste zadatak za {{date}}',
  confirmYourTask: 'Potvrdite vaš zadatak',
  confirmYourTaskGX: 'Potvrdite vaš zadatak iz GX-a',
  confirmHighPriorityTaskMessage:
    'Da li ste sigurni da zelite da postavite krajnji datum završetka zadatak za danas? velika je verovatnoća da on neće biti danas potvrđen niti završen.',
  setTomorrow: 'Postavi za sutra',
  keepToday: 'Zadrži za danas',
  createTask: 'Kreiraj zadatak',
  confirmationNeeded: 'Sa zahtevom za potvrdu',
  pictureTooBlurry: `Slika je preblura. Molimo vas, uslikajte jasnu fotografiju i učitajte je.`,
  tryAgain: `Pokušajte ponovno`,
};
