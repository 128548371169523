import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Take out trash',
  [StandardizedTask.SMOKE]: 'Room smells like smoke',
  [StandardizedTask.SOFA_1]: 'Prepare the sofa for 1 PAX',
  [StandardizedTask.SOFA_2]: 'Prepare the sofa for 2 PAX',
  [StandardizedTask.BABY_BED]: 'Bring baby bed',
  [StandardizedTask.DRYING_RACK]: 'Bring drying rack',
  [StandardizedTask.TOWELS]: 'Bring towels',
  [StandardizedTask.IRON_AND_BOARD]: 'Bring iron & board',
  [StandardizedTask.TOILET_PAPER]: 'Bring toilet paper',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Check no-show from 10 AM',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Party Room - check for damage',
  [StandardizedTask.LCO_11_30]: 'LCO at 11:30 AM',
  [StandardizedTask.LCO_12]: 'LCO at 12 PM',
  [StandardizedTask.QUARANTINE]: 'QUARANTINE - HSK do not enter room',
  [StandardizedTask.COFFEE_PODS]: 'Bring extra coffee pods',
  [StandardizedTask.KITCHEN_TOWEL]: 'Bring kitchen towel',
  [StandardizedTask.BLANKET]: 'Bring a blanket',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Check for mail',
  [StandardizedTask.BABY_CHAIR]: 'Bring baby chair',
  [StandardizedTask.TURN_HEATING_ON]: 'Turn heating on before CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Bring sorry treatment',
  [StandardizedTask.REFILL_HSK_HUB]: 'Refill HSK hub',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Bring fan to room',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: 'Do not disturb guest',
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Prioritize room cleaning',
  [StandardizedTask.SHAMPOO_REFILL]: 'Shampoo refill',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Shower gel refill',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Body lotion refill',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'Bring kitchen towel',
  [StandardizedTask.BRING_FRYING_PAN]: 'Bring frying pan',
  [StandardizedTask.BRING_COOKING_POT]: 'Bring cooking pot',
  [StandardizedTask.BRING_WATER_GLASS]: 'Bring water glass / glasses',
  [StandardizedTask.BRING_WINE_GLASS]: 'Bring wine glass / glasses',
  [StandardizedTask.BRING_CUP]: 'Bring cup',
  [StandardizedTask.BRING_PLATES]: 'Bring plates',
  [StandardizedTask.BRING_BOWL]: 'Bring bowl',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'Bring paper towels',
  [StandardizedTask.CLEAN_ELEVATOR]: 'Clean elevator',
  [StandardizedTask.CLEAN_ENTRANCE]: 'Clean entrance',
  [StandardizedTask.CLEAN_HALLWAYS]: 'Clean hallways',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'Clean laundry room',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'Clean luggage lockers',
  [StandardizedTask.CLEAN_STAIRS]: 'Clean stairs',
  [StandardizedTask.CLEAN_TOILET]: 'Clean toilet',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'Clean public toilets',
  [StandardizedTask.CLEAN_TERRACE]: 'Clean terrace',
  [StandardizedTask.CLEAN_BALCLONY]: 'Clean balcony',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'Clean washing machines & dryers (laundry room)',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'Double-check room',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'Check laundry delivery',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'Check expiration date of consumables',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'Check (reset) luggage lockers',
  [StandardizedTask.CHECK_MAILBOX]: 'Check mailbox',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'Refill essential closet',
  [StandardizedTask.REFILL_DETERGENT]: 'Refill detergent',
  [StandardizedTask.ORGANIZE_STORAGE]: 'Organize storage',
  [StandardizedTask.ORDER_CRISPS]: 'Order crisps',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Order juice / soft drink',
  [StandardizedTask.ORDER_BEER]: 'Order beer',
  [StandardizedTask.ORDER_WATER]: 'Order water',
  [StandardizedTask.ORDER_EARPLUGS]: 'Order earplugs',
  [StandardizedTask.ORDER_SWEETS]: 'Order sweets / Candy Kittens',
  [StandardizedTask.ORDER_COFFEE]: 'Order coffee / Grind',
  [StandardizedTask.ORDER_TEA]: 'Order tea / Hoogly',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'Take out dirty laundry',
  [StandardizedTask.WATER_FLOWERS]: 'Water the flowers',
  [StandardizedTask.WATER_GARDEN]: 'Water the garden',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Badkamer ventilator luidruchtig',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Licht werkt niet',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Wastafel verstopt',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Doucheputje verstopt',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Badkamerfilter',
  [StandardizedDamageReport.SINK]: 'Wastafel',
  [StandardizedDamageReport.TOILET]: 'Toilet',
  [StandardizedDamageReport.SHOWER]: 'Douche',
  [StandardizedDamageReport.STOVE]: 'Fornuis',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Koelkast/Minibar',
  [StandardizedDamageReport.DOOR]: 'Deur',
  [StandardizedDamageReport.WINDOW]: 'Raam',
  [StandardizedDamageReport.AC]: 'Airco',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Gloeilamp',
  [StandardizedDamageReport.CHAIRS]: 'Stoelen',
  [StandardizedDamageReport.HEATING]: 'Verwarming',
  [StandardizedDamageReport.TV]: 'TV',
  [StandardizedDamageReport.TABLES]: 'Tafels',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: `HSK Team`,
  [Team.MAINTENANCE]: `Onderhoudsteam`,
  [Team.OPERATIONS]: `Operationeel team`,
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: `Keuken`,
  [DamageReportArea.BED]: `Bed`,
  [DamageReportArea.BATHROOM]: `Badkamer`,
  [DamageReportArea.SEATING_AREA]: `Zithoek`,
  [DamageReportArea.WALLS_CEILING_FLOOR]: `Muren/Plafond/Vloer`,
  [DamageReportArea.ICT]: `ICT`,
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: `Schoon `,
  [DamageReportActionRequired.REPLACE]: `Vervangen`,
  [DamageReportActionRequired.REPAIR]: `Reparatie`,
  [DamageReportActionRequired.CHECK]: `Controleren`,
  [DamageReportActionRequired.OTHER]: `Andere`,
};

const taskPriorities = {
  [TaskPriority.LOW]: `Standaard`,
  [TaskPriority.HIGH]: `Hoog`,
};

const popupNotifications = {
  taskDeletionNotification: `Taak is succesvol verwijderd.`,
  lostAndFoundDeletionNotification: `Gevonden voorwerp is succesvol verwijderd`,
  damageReportDeletionNotification: `Schademelding is succesvol verwijderd.`,
  taskCreationNotification: `Taak is succesvol aangemaakt.`,
  lostAndFoundCreationNotification: `Lost & found item is succesvol aangemaakt`,
  damageReportCreationNotification: `Schademelding is succesvol aangemaakt.`,
  taskCompletionNotification: `Taak is succesvol voltooid.`,
  lostAndFoundCompletionNotification: `Lost & found item is succesvol opgelost`,
  damageReportCompletionNotification: `Schademelding is succesvol opgelost.`,
  taskUpdateNotification: `Taak is succesvol bijgewerkt`,
  lostAndFoundUpdateNotification: `Gevonden voorwerpen zijn succesvol bijgewerkt`,
  damageReportUpdateNotification: `Schademelding is succesvol bijgewerkt.`,
  pleaseSelectRoomsNotification: `Selecteer kamers`,
  noShowReportNotification: `No show succesvol gemeld voor kamer {{nummer}}`,
  checkInReportNotification: `Check-in succesvol gemeld voor kamer {{nummer}}.`,
  damageReportWorkingTimeScheduledNotification: `Schademelding succesvol ingepland voor {{date}}.`,
  hskDelayNotification: `Succesvolle HSK uitstel voor kamer {{nummer}}. Inchecktijd is bijgewerkt naar {{tijd}}.`,
};

const cleaningInterval = {
  next: `Volgende schoonmaak`,
  interval: `Schoonmaakinterval`,
  timeRange: `Tijdsduur`,
  lastCleaning: `Laatste schoonmaak`,
  stayoverCleaning: `Stayover schoonmaak`,
  [CleaningInterval.WEEKLY_OR_END]: `Wekelijks of einde verblijf`,
  [CleaningInterval.BIWEEKLY]: `Tweewekelijks`,
  [CleaningInterval.DAILY]: `Dagelijks`,
  [CleaningInterval.NONE]: `Geen`,
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  cleaned: `Schoongemaakt`,
  dirty: `Vuil`,
  readyToClean: `Klaar voor schoonmaak`,
  stayoverReadyToClean: `Stayover - Klaar voor schoonmaak`,
  allRoomsCleaned: `Alle kamers schoongemaakt`,
  occupiedCleaningToday: `Bezet - Schoonmaak Vandaag`,
  readyToInspect: `Klaar om te inspecteren`,
  stayoverInspection: `Stayover inspectie`,
  vacant: `Beschikbaar`,
  occupied: `Bezet`,
  clean: `Schoon`,
  daily: `Dagelijks`,
  weekly: `Wekelijks`,
  biweekly: `Tweewekelijks`,
  monthly: `Maandelijks`,
  none: `Geen`,
  tasks: `Taken`,
  notifications: `Meldingen`,
  more: `Meer`,
  select: `Selecteren`,
  property: `Gebouw`,
  result: `Resultaat`,
  results: `Resultaten`,
  properties: `Gebouwen`,
  rooms: `Kamers`,
  team: `Team`,
  dueDate: `Vervaldatum`,
  date: `Datum`,
  close: `Sluiten`,
  search: `Zoeken`,
  login: `Inloggen`,
  loading: `Laden...`,
  anErrorHasOccurred: `Er is een fout opgetreden.`,
  chooseDate: `Kies datum`,
  selectDate: `Selecteer datum`,
  selectADate: `Selecteer een datum`,
  markAsClean: `Markeren als schoon`,
  markAsReadyToInspect: `Markeren als gereed om te inspecteren`,
  viewTasks: `Taken weergeven`,
  eci: `ECI`,
  lco: `LCO `,
  seeDetails: `Zie details`,
  assignedTasks: `Toegewezen taken`,
  delete: `Verwijder`,
  deleteTask: `Taak verwijderen`,
  roomLog: `Kamerlogboek`,
  taskLog: `Takenlogboek`,
  deleteTaskConfirmationMessage: `Weet je zeker dat je deze taak wilt verwijderen?`,
  deleteTaskRepeatsConfirmationMessage: `Dit is een herhalende taak. Je kunt alleen deze verwijderen of ook de toekomstige herhalende taken.`,
  deleteOnlyThisTask: `Verwijder alleen deze taak.`,
  deleteRepeatingTask: `Verwijder herhalende taak`,
  yes: `Ja`,
  no: `Nee`,
  pleaseWaitAFewSeconds: `Even geduld a.u.b.`,
  deleting: `Verwijderen`,
  lostAndFound: `Gevonden voorwerpen`,
  current: `Huidige`,
  archive: `Archief`,
  edit: `Bewerken`,
  markAsResolved: `Markeren als opgelost`,
  markAsUnresolved: `Markeren als onopgelost`,
  add: `Toevoegen`,
  area: `Gebied`,
  addTask: `Taak toevoegen`,
  editTask: `Taak bewerken`,
  addArea: `Gebied toevoegen`,
  addAreaDescription: `Voeg een gebied van het hotel toe voor deze taak`,
  selectProperty: `Selecteer gebouw`,
  selectRooms: `Selecteer kamers`,
  selectTeam: `Selecteer team`,
  selectDueDate: `Selecteer datum`,
  hskTeam: `HSK Team`,
  save: `Opslaan`,
  writeTask: `Schrijf taak`,
  repetitionTime: `Herhalingsperiode`,
  doesNotRepeat: `Herhaalt zich niet.`,
  day: `Dag`,
  week: `Week`,
  month: `Maand`,
  repeatOn: `Herhaal op`,
  repeatsEvery: `Herhaalt zich elke`,
  description: `Beschrijving`,
  actionIsRequired: `Actie is vereist. Verwijder titel en selecteer een actie uit de lijst.`,
  noActionSelected: `Geen actie geselecteerd`,
  changeSearchAction: `Probeer uw zoekterm te wijzigen`,
  noActionFound: `Geen actie gevonden`,
  selectActionHelp: `Kan je een actie niet vinden? Neem dan contact op met het Operations team zodat zij het kunnen aanvragen.`,
  writeToSelectAction: 'Schrijf om een actie te selecteren',
  addDescription: `Beschrijving toevoegen`,
  title: `Titel`,
  addTitle: `Titel toevoegen`,
  titleIsRequired: `Titel is verplicht.`,
  titleMaxLength: `De titel moet minder zijn dan {{maxLength}} tekens.`,
  teamIsRequired: `Team is vereist`,
  openTasks: `Open taken`,
  arrival: `Aankomst`,
  departure: `Vertrek`,
  checkIn: `Inchecken`,
  times: `Tijden`,
  guests: `Gasten`,
  name: `Naam`,
  additionalInfo: `Aanvullende informatie`,
  addLostAndFound: `Verloren voorwerpen toevoegen`,
  addDamageReport: `Schademelding toevoegen`,
  unitIsRequired: `Unit is vereist`,
  addItem: `Item toevoegen`,
  editItem: `Item bewerken`,
  startCleaning: `Begin schoonmaak`,
  endCleaning: `Einde schoonmaak`,
  endCleaningMessage: `Er is 1 openstaande taak. Weet je zeker dat je wilt stoppen met schoonmaken?`,
  endCleaningMessage_plural: `Er zijn {{count}} openstaande taken. Weet je zeker dat je wilt afronden met schoonmaken?`,
  cancelAndViewTasks: `Annuleren en taken bekijken`,
  ignoreAndMarkAsReadyToInspect: `Negeren en markeren als gereed voor inspectie`,
  ignoreAndMarkAsCleaned: `Negeren en markeren als schoongemaakt`,
  damageReports: `Schademeldingen`,
  confirm: `Bevestigen`,
  selectRoomOrArea: `Selecteer kamer/ruimte of`,
  createNewOne: `Nieuwe aanmaken`,
  today: `Vandaag`,
  tomorrow: `Morgen`,
  noTasksMessage: `Geen taak is toegevoegd`,
  cleaningStatus: `Schoonmaak`,
  logOut: `Uitloggen`,
  language: `Taal`,
  selectLanguage: `Selecteer Taal`,
  isStandardized: `Is het gestandaardiseerd?`,
  selectStandardizedTask: `Selecteer gestandaardiseerde taak`,
  overdue: `Achterstallig`,
  arrivals: `Aankomsten`,
  departures: `Vertrekken`,
  stayovers: `Overnachtingen`,
  occupancy: `OTB bezetting`,
  midstayCleaning: `Tussentijdse schoonmaak`,
  roomsToSell: `Kamers te koop`,
  editImages: `Bewerken`,
  toClean: `schoonmaken`,
  toInspect: `inspecteren`,
  noRoomsForCleaningToday: `Geen kamers voor schoonmaak vandaag`,
  noShow: `No show`,
  noShowReportConfirmationMessage: `Bevestig alstublieft dat kamer nummer {{number}} niet aanwezig is.`,
  noShowReportQuestion: `Heeft de gast gisteren ingecheckt?`,
  checkInReportConfirmationMessage: `Bevestig dat de gast is ingecheckt op kamer nummer {{number}}.`,
  noResultsSearchMessage: `Geen resultaten voldoen aan je zoekcriteria.`,
  cancel: `Annuleren`,
  selectCleaner: `Kies schoonmaker`,
  selectAll: `Alle selecteren`,
  floor: `Verieping`,
  notAssigned: `Niet toegewezen`,
  assignXRooms: `{{total}} kamers toewijzen`,
  assignRooms: `Kamers toewijzen`,
  approvedLCOs: `Goedgekeurde LCO's`,
  cleaner: `Schoonmaker`,
  roomsCleanForToday: `Alle kamers zijn schoon voor vandaag!🎉🎉`,
  viewDamageReports: `Bekijk schademeldingen`,
  noTasks: `Geen taken`,
  noDamageReports: `Geen schademeldingen `,
  dailyCleaningReport: `Dagelijks Schoonmaakrapport`,
  stayover: `Overnachting`,
  roomMoveFrom: `Kamer verhuizing van {{value}}`,
  roomMoveTo: `Kamer verhuizen naar {{value}}`,
  unassignAll: `{{total}} kamers afwijzen`,
  unassignRoomsConfirmationMessage: `Weet je zeker dat je {{total}} kamers wilt afwijzen?`,
  unassignRoomsTitle: `Kamers afwijzen`,
  noRoomAssigned: `Geen kamer toegewezen`,
  actionRequired: `Actie vereist`,
  priority: `Prioriteit`,
  lcoUntil: `LCO Tot`,
  created: `Aangemaakt`,
  startNow: `Nu beginnen`,
  scheduleTime: `Tijd plannen`,
  viewInfo: `Bekijk informatie`,
  reminder: `Herinnering`,
  reminderTaskNotificationText: `Uw geplande taak staat voor vandaag gepland.`,
  inProgress: `In uitvoering`,
  scheduled: `Gepland`,
  moveMidstayCleaning: `Verplaats schoonmaak tijdens verblijf?`,
  moveMidstayTitle: `Verplaats Midstay`,
  moveMidstayDescription: `Weet je zeker dat je de midstay schoonmaak wilt verplaatsen naar {{date}}?`,
  moveMidstayNotPossible: `Sorry! De midstay schoonmaak van deze kamer kan niet worden verplaatst naar een andere dag!`,
  moveMidstayNotification: `Stayover schoonmaak is succesvol verplaatst naar {{datum}}.`,
  pleaseContactOps: `Gelieve contact op te nemen met het Operations-team.`,
  midCleanMovedTo: `Stayover schoonmaak verplaatst naar`,
  optional: `Optioneel`,
  hskDelay: `HSK vertraging`,
  hskDelays: `HSK Vertragingen `,
  hskDelayMessage: `Update inchecktijd voor kamer {{nummer}}`,
  notStarted: `Nog niet begonnen`,
  resolved: `Opgelost`,
  noDamageReportsToday: `Geen schademeldingen voor vandaag`,
  yourTask: `Jouw taak`,
  yourTaskGX: `Jouw taak van GX`,
  damageReportedByGX: `Schade gemeld door GX`,
  stay: `Verblijf`,
  checkOut: `Uitchecken`,
  noPermission: `Geen toestemming`,
  approvedCheckoutTime: `Goedgekeurde uitchecktijd`,
  requestedCheckoutTime: `Aangevraagde uitchecktijd`,
  checkinTime: `Inchecktijd`,
  commentForHousekeeping: `Opmerking voor houskeeping`,
  numberOfApprovedLCOs: `Aantal goedgekeurde LCO's`,
  notSet: `Niet ingesteld`,
  vip: `VIP`,
  extraService: `Extra service`,
  reservationMissing: `Reservering is hoogstwaarschijnlijk geannuleerd, schakel in plaats daarvan over naar het Algemeen-tabblad.`,
  confirmFor: `Bevestig voor {{date}}`,
  confirmAnotherDay: `Bevestig een andere dag`,
  taskConfirmed: `Je hebt de taak bevestigd voor {{date}}`,
  createTask: `Taak creëren`,
  confirmHighPriorityTaskMessage: `Wil je de vervaldatum echt op vandaag zetten? Het is waarschijnlijk dat de taak vandaag niet zal worden bevestigd en voltooid.`,
  confirmYourTaskGX: `Bevestig je taak van GX.`,
  confirmYourTask: `Bevestig je taak`,
  setTomorrow: `Morgen instellen`,
  keepToday: `Vandaag houden`,
  confirmationNeeded: `Bevestiging nodig`,
  moreFilters: `Meer filters`,
  reset: `Herinitialiseren`,
  action: `Acties tonen`,
  actionsSelected: `{{total}}  actie(s) geselecteerd`,
  takePhoto: `Maak foto`,
  seeTaskDetails: `Zie taak`,
  savePictures: `Opslaan afbeeldingen`,
  savePicturesAndStartCleaning: `Opslaan en begin met schoonmaken`,
  repetition: `Herhaling`,
  noRepetition: `Geen herhaling`,
  hideActions: `Verberg acties`,
  disableActionTitleModal: `Reset de {{name}} filter`,
  disableActionDescriptionModal: `Om de {{currentFilter}} filter te gebruiken, gelieve de {{currentFilter}} filter te resetten.`,
  resetAllFilters: `Reset alle filters`,
  snacks: 'SNACKS',
  memberArrivals: 'Aankomsten van leden',
  showActions: 'Acties tonen',
  togglePushNotificationsLabel: 'Push-meldingen voor iOS',
  pictureTooBlurry: `De foto is te onscherp. Neem alstublieft een duidelijke foto en upload deze.`,
  tryAgain: `Probeer opnieuw`,
};
