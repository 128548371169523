import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Винесіть сміття',
  [StandardizedTask.SMOKE]: 'У кімнаті пахне димом',
  [StandardizedTask.SOFA_1]: 'Підготуйте диван на 1 PAX',
  [StandardizedTask.SOFA_2]: 'Підготуйте диван на 2 PAX',
  [StandardizedTask.BABY_BED]: 'Принесіть дитяче ліжко',
  [StandardizedTask.DRYING_RACK]: 'Принесіть ссушилку',
  [StandardizedTask.TOWELS]: 'Принесіть рушники',
  [StandardizedTask.IRON_AND_BOARD]: 'Принесіть праску та дошку',
  [StandardizedTask.TOILET_PAPER]: 'Принесіть туалетний папір',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Перевірте неявку з 10:00',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Вечірка - перевірити на наявність пошкоджень',
  [StandardizedTask.LCO_11_30]: 'LCO у 11:30',
  [StandardizedTask.LCO_12]: 'LCO о 12:00',
  [StandardizedTask.QUARANTINE]: 'КАРАНТИН - HSK не заходьте в приміщення',
  [StandardizedTask.COFFEE_PODS]: 'Принесіть додаткові кавові стручки',
  [StandardizedTask.KITCHEN_TOWEL]: 'Принесіть кухонний рушник',
  [StandardizedTask.BLANKET]: 'Принесіть ковдру',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Перевірте пошту',
  [StandardizedTask.BABY_CHAIR]: 'Принесіть дитячий стілець',
  [StandardizedTask.TURN_HEATING_ON]: 'Увімкніть обігрів перед CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Вибачте лікування',
  [StandardizedTask.REFILL_HSK_HUB]: 'Заправка втулки HSK',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Принесіть в кімнату вентилятор',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: 'Не турбуйте гостя',
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Надайте пріоритет прибиранню кімнати',
  [StandardizedTask.SHAMPOO_REFILL]: 'Заправка шампуню',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Заправка гелю для душу',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Заправка лосьйону для тіла',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'Принесіть кухонний рушник',
  [StandardizedTask.BRING_FRYING_PAN]: 'Принесіть сковорідку',
  [StandardizedTask.BRING_COOKING_POT]: 'Принесіть каструлю з собою',
  [StandardizedTask.BRING_WATER_GLASS]: 'Принесіть склянку для води',
  [StandardizedTask.BRING_WINE_GLASS]: 'Принесіть келих для вина',
  [StandardizedTask.BRING_CUP]: 'Принеси кубок',
  [StandardizedTask.BRING_PLATES]: 'Принесіть тарілки',
  [StandardizedTask.BRING_BOWL]: 'Принесіть чашу',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'Візьміть з собою паперові рушники',
  [StandardizedTask.CLEAN_ELEVATOR]: 'Чистий ліфт',
  [StandardizedTask.CLEAN_ENTRANCE]: 'Чистий вхід',
  [StandardizedTask.CLEAN_HALLWAYS]: 'Чисті коридори',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'Чиста пральня',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'Чисті камери схову',
  [StandardizedTask.CLEAN_STAIRS]: 'Чисті сходи',
  [StandardizedTask.CLEAN_TOILET]: 'Чистий туалет',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'Чисті громадські туалети',
  [StandardizedTask.CLEAN_TERRACE]: 'Чиста тераса',
  [StandardizedTask.CLEAN_BALCLONY]: 'Чистий балкон',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'Чисті пральні та сушильні машини',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'Подвійна перевірка номера',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'Перевірка доставки білизни',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'Перевірити термін придатності витратних матеріалів',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'Перевірка та скидання багажних шафок',
  [StandardizedTask.CHECK_MAILBOX]: 'Перевірити поштову скриньку (нова гостьова пошта?)',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'Поповнити необхідну шафу',
  [StandardizedTask.REFILL_DETERGENT]: 'Заправка миючого засобу',
  [StandardizedTask.ORGANIZE_STORAGE]: 'Організувати зберігання',
  [StandardizedTask.ORDER_CRISPS]: 'Замовити чіпси',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Замовити сік / безалкогольний напій',
  [StandardizedTask.ORDER_BEER]: 'Замовити пиво',
  [StandardizedTask.ORDER_WATER]: 'Замовити воду',
  [StandardizedTask.ORDER_EARPLUGS]: 'Замовити Беруші',
  [StandardizedTask.ORDER_SWEETS]: 'Замовити солодощі',
  [StandardizedTask.ORDER_COFFEE]: 'Замовити каву',
  [StandardizedTask.ORDER_TEA]: 'Замовити чай',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'Виносити брудну білизну',
  [StandardizedTask.WATER_FLOWERS]: 'Поливаємо квіти',
  [StandardizedTask.WATER_GARDEN]: 'Поливаємо сад',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Голосний вентилятор у ванній кімнаті',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Світло не працює',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Раковина заблокована',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Злив для душу заблокований',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Фільтр для ванної кімнати',
  [StandardizedDamageReport.SINK]: 'Раковина',
  [StandardizedDamageReport.TOILET]: 'Туалет',
  [StandardizedDamageReport.SHOWER]: 'Душ',
  [StandardizedDamageReport.STOVE]: 'піч',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Холодильник/міні-бар',
  [StandardizedDamageReport.DOOR]: 'двері',
  [StandardizedDamageReport.WINDOW]: 'Вікно',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wi-Fi',
  [StandardizedDamageReport.LIGHTBULB]: 'Лампочка',
  [StandardizedDamageReport.CHAIRS]: 'Стільці',
  [StandardizedDamageReport.HEATING]: 'Опалення',
  [StandardizedDamageReport.TV]: 'телевізор',
  [StandardizedDamageReport.TABLES]: 'Таблиці',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Команда HSK',
  [Team.MAINTENANCE]: 'Команда технічного обслуговування',
  [Team.OPERATIONS]: 'Оперативная група',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Кухня',
  [DamageReportArea.BED]: 'Ліжко',
  [DamageReportArea.BATHROOM]: 'Ванна кімната',
  [DamageReportArea.SEATING_AREA]: 'Зона відпочинку',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Стіни/Стеля/Підлога',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Чистий',
  [DamageReportActionRequired.REPLACE]: 'Замінити',
  [DamageReportActionRequired.REPAIR]: 'Ремонт',
  [DamageReportActionRequired.CHECK]: 'Перевірте',
  [DamageReportActionRequired.OTHER]: 'Інший',
};

const taskPriorities = {
  [TaskPriority.LOW]: '"Стандартний',
  [TaskPriority.HIGH]: 'Високий',
};

const popupNotifications = {
  taskDeletionNotification: 'Завдання успішно видалено',
  lostAndFoundDeletionNotification: 'Втрачений та знайдений елемент успішно видалено',
  damageReportDeletionNotification: 'Звіт про пошкодження успішно видалено',
  taskCreationNotification: 'Завдання успішно створено',
  lostAndFoundCreationNotification: 'Втрачений та знайдений елемент успішно створено',
  damageReportCreationNotification: 'Звіт про пошкодження успішно створено',
  taskCompletionNotification: 'Завдання успішно виконано',
  lostAndFoundCompletionNotification: 'Втрачений та знайдений елемент успішно вирішено',
  damageReportCompletionNotification: 'Звіт про пошкодження успішно вирішено',
  taskUpdateNotification: 'Завдання успішно оновлено',
  lostAndFoundUpdateNotification: 'Втрачений та знайдений елемент успішно оновлено',
  damageReportUpdateNotification: 'Звіт про пошкодження успішно оновлено',
  pleaseSelectRoomsNotification: 'Виберіть кімнати',
  noShowReportNotification: 'повідомлення про "неявку" у кімнату {{number}} успішно зареєстровано',
  checkInReportNotification: 'Check in успішно повідомлено для кімнати {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Звіт про пошкодження успішно заплановано на {{date}}',
  hskDelayNotification: 'Успішна затримка HSK для кімнати {{number}}. Час реєстрації оновлено на {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Більше фільтрів',
  resetAllFilters: 'Скинути всі фільтри',
  reset: 'Скидання',
  cleaned: 'Прибрано',
  allRoomsCleaned: `Усі номери прибрано`,
  dirty: 'Брудний',
  readyToClean: 'Готовий до прибирання',
  stayoverReadyToClean: ' перебування - готовність до прибирання',
  occupiedCleaningToday: 'Зайнято - прибирання сьогодні',
  readyToInspect: 'Готовий до огляду',
  stayoverInspection: 'Перевірка перебування',
  vacant: 'Вакантний',
  occupied: 'Зайнято',
  clean: 'Чистий',
  daily: 'Щоденно',
  weekly: 'Щотижня',
  biweekly: 'Кожні два тижні',
  monthly: 'Щомісяця',
  none: 'Жодного',
  tasks: 'Завдання',
  notifications: 'Повідомлення',
  more: 'Більше',
  select: 'Виберіть',
  property: 'Готель',
  result: 'Результат',
  results: 'Результати',
  properties: 'Готелі',
  rooms: 'Кімнати',
  team: 'Команда',
  dueDate: 'Термін виконання',
  date: 'Дата',
  close: 'Закрити',
  search: 'Пошук',
  login: 'Увійти',
  loading: 'Завантаження ...',
  anErrorHasOccurred: 'Сталася помилка',
  chooseDate: 'Виберіть дату',
  selectDate: 'Виберіть дату',
  selectADate: 'Виберіть дату',
  markAsClean: 'Позначити як прибраний',
  markAsReadyToInspect: 'Позначити як готового до огляду',
  viewTasks: 'Перегляд завдань',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Журнал номерів`,
  taskLog: `Запис завдань`,
  seeDetails: 'Дивіться деталі',
  assignedTasks: 'Доручені завдання',
  delete: 'Видалити',
  deleteTask: 'Видалити завдання',
  deleteTaskConfirmationMessage: 'Ви впевнені, що хочете видалити це завдання?',
  deleteTaskRepeatsConfirmationMessage:
    'Це повторюване завдання. Ви також можете видалити лише це або усі повторювані майбутні завдання',
  deleteOnlyThisTask: 'Видалити лише це завдання',
  deleteRepeatingTask: 'Видалити повторюване завдання',
  yes: 'Так',
  no: 'Hі',
  pleaseWaitAFewSeconds: 'Зачекайте кілька секунд',
  deleting: 'Видалення',
  lostAndFound: 'Бюро знахідок',
  current: 'Поточний',
  archive: 'Архів',
  edit: 'Редагувати',
  markAsResolved: 'Позначити як вирішене',
  markAsUnresolved: 'Позначити як невирішене',
  add: 'Додати',
  area: 'Площа',
  addTask: 'Додати завдання',
  editTask: 'Редагувати завдання',
  seeTaskDetails: 'Переглянути завдання.',
  savePicturesAndStartCleaning: 'Зберегти та почати прибирання',
  savePictures: 'Зберегти зображення',
  takePhoto: 'Зробити фото',
  addArea: 'Додати область',
  addAreaDescription: 'Додайте частину готелю для цього завдання',
  selectProperty: 'Виберіть нерухомість/готель',
  selectRooms: 'Виберіть кімнати',
  selectTeam: 'Виберіть команду',
  selectDueDate: 'Виберіть дату',
  hskTeam: 'Команда HSK',
  save: 'Зберегти',
  actionIsRequired: `Дія потрібна. Видаліть заголовок та виберіть дію зі списку.`,
  noActionSelected: `Обрано жодних дій`,
  changeSearchAction: `Спробуйте змінити свій пошуковий запит`,
  noActionFound: `Дія не знайдена`,
  selectActionHelp: `Не можете знайти дію? Будь ласка, зверніться до команди Операцій, щоб вони могли запросити її.`,
  writeToSelectAction: 'Напишіть, щоб вибрати дію',
  writeTask: 'Написати завдання',
  repetition: 'Повторення',
  noRepetition: 'Нема повторення',
  repetitionTime: 'Термін повторення',
  doesNotRepeat: 'Не повторюється',
  day: 'День',
  week: 'Тиждень',
  month: 'Місяць',
  repeatOn: 'Повторіть ',
  repeatsEvery: 'Повторюється кожен',
  description: 'Опис',
  title: 'Заголовок',
  addTitle: 'Додати заголовок',
  titleIsRequired: "Заголовок обов'язковий",
  titleMaxLength: 'Назва повинна бути менше {{maxLength}} символів',
  teamIsRequired: 'Потрібна команда',
  openTasks: 'Відкриті завдання',
  arrival: 'Прибуття',
  departure: 'Виїзд',
  checkIn: 'Реєстрація ',
  times: 'Часи',
  guests: 'Гості',
  name: "Ім'я",
  additionalInfo: 'Додаткова інформація',
  addLostAndFound: 'Додайте втрачене та знайдене',
  addDamageReport: 'Додати інформацію про пошкодження',
  unitIsRequired: 'Одиниця потрібна',
  addItem: 'Додати елемент',
  editItem: 'Редагувати елемент',
  startCleaning: 'Почати прибирання',
  endCleaning: 'Завершити прибирання',
  endCleaningMessage: 'Є 1 відкрите завдання. Ви впевнені, що хочете закінчити прибирання?',
  endCleaningMessage_plural: 'Є {{count}} відкриті завдання. Ви впевнені, що хочете закінчити прибирання?',
  cancelAndViewTasks: 'Скасувати та переглянути завдання',
  ignoreAndMarkAsReadyToInspect: 'Ігнорувати та позначати як готовий до перевірки',
  ignoreAndMarkAsCleaned: 'Ігнорувати та позначати як прибрано',
  damageReports: 'Повідомлення про пошкодження',
  confirm: 'Підтвердити',
  selectRoomOrArea: 'Виберіть кімнату/зону або',
  createNewOne: 'створити нову',
  today: 'Сьогодні',
  tomorrow: 'Завтра',
  noTasksMessage: 'Жодне завдання не додано',
  cleaningStatus: 'Прибирання',
  logOut: 'Вийти',
  language: 'Мова',
  selectLanguage: 'Оберіть мову',
  isStandardized: 'Чи стандартизовано?',
  selectStandardizedTask: 'Виберіть стандартизоване завдання',
  overdue: 'Прострочений',
  arrivals: 'Заїзд',
  memberArrivals: 'Member arrivals',
  departures: 'Виїзд',
  stayovers: 'Прибирання в середині строку проживання',
  occupancy: 'Зайнятість ОТБ',
  midstayCleaning: 'Прибирання в середині строку проживання',
  roomsToSell: 'Кімнати для продажу',
  editImages: 'Редагувати зображення',
  toClean: 'прибрати',
  toInspect: 'перевірити',
  noRoomsForCleaningToday: 'Сьогодні немає кімнат для прибирання',
  noShow: 'Неявка',
  noShowReportConfirmationMessage: 'Підтвердьте, що в номер {{number}} не було реєстрації.',
  noShowReportQuestion: 'Гість зареєструвався вчора?',
  checkInReportConfirmationMessage: 'Будь ласка, підтвердьте, що гість зареєструвався в номері {{number}}.',
  noResultsSearchMessage: 'Жоден результат не відповідає вашим критеріям пошуку',
  cancel: 'Скасувати',
  selectCleaner: 'Виберіть прибиральника',
  selectAll: 'Вибрати все',
  floor: 'Поверх',
  notAssigned: 'Не призначено',
  assignXRooms: 'Призначити {{всього}} кімнат',
  assignRooms: 'Призначити кімнати',
  approvedLCOs: 'Схвалені LCO',
  cleaner: 'Прибиральник',
  roomsCleanForToday: 'Всі номери на сьогодні чисті!🎉🎉',
  viewDamageReports: 'Переглянути звіти про пошкодження',
  noTasks: 'Немає завдань',
  noDamageReports: 'Повідомлень про пошкодження немає',
  dailyCleaningReport: 'Щоденний звіт про прибирання',
  stayover: 'Перебування',
  roomMoveFrom: 'Переміщення кімнати з {{value}}',
  roomMoveTo: 'Переміщення кімнати до {{value}}',
  unassignAll: 'Скасувати призначення всіх',
  unassignRoomsConfirmationMessage: 'Ви впевнені, що хочете скасувати призначення {{total}} кімнат?',
  unassignRoomsTitle: 'Скасувати призначення кімнат',
  noRoomAssigned: 'Приміщення не призначено',
  action: 'Дія',
  hideActions: 'Приховати дії',
  showActions: 'Показати дії',
  actionsSelected: '{{total}}  дія(і) вибрано',
  disableActionTitleModal: 'Скинути фільтр {{name}}',
  disableActionDescriptionModal:
    'Щоб скористатися фільтром {{currentFilter}}, будь ласка, скиньте фільтр {{resetFilter}}.',
  actionRequired: 'Потрібна дія',
  priority: 'Пріоритет',
  lcoUntil: 'LCO доки',
  created: 'Створено',
  startNow: 'Розпочати зараз',
  scheduleTime: 'Розклад часу',
  viewInfo: 'Переглянути інформацію',
  reminder: 'Нагадування',
  reminderTaskNotificationText: 'Виконання вашого запланованого завдання сьогодні',
  inProgress: 'В процесі',
  scheduled: 'За розкладом',
  moveMidstayCleaning: 'Перемістити прибирання Stayover?',
  moveMidstayTitle: 'Move Midstay',
  moveMidstayDescription: 'Ви впевнені, що хочете перенести проміжне прибирання на {{date}}?',
  moveMidstayNotPossible: 'Вибачте! Проміжне прибирання в цьому номері не можна перенести на інший день!',
  moveMidstayNotification: 'Прибирання відстаней успішно перенесено на {{date}}.',
  pleaseContactOps: 'Будь ласка, зв’яжіться з оперативним відділом',
  midCleanMovedTo: 'Прибирання переміщено до: {{value}}',
  optional: "Необов'язковий",
  hskDelay: 'Затримка HSK',
  hskDelays: 'Затримки HSK',
  hskDelayMessage: 'Оновіть час заїзду в номер {{number}}',
  notStarted: 'Не розпочато',
  resolved: 'Вирішено',
  noDamageReportsToday: 'Повідомлень про пошкодження на сьогодні немає',
  yourTask: 'Ваше завдання',
  yourTaskGX: 'Ваше завдання від GX',
  damageReportedByGX: 'Пошкодження повідомляє GX',
  stay: 'Дати перебування',
  checkOut: 'Час виїзду',
  noPermission: 'Ви не маєте дозволу, будь ласка, зверніться до підтримки.',
  approvedCheckoutTime: 'Затверджений час виїзду',
  requestedCheckoutTime: 'Запитаний час виїзду',
  checkinTime: 'Час реєстрації',
  commentForHousekeeping: 'Коментар для господарства',
  numberOfApprovedLCOs: 'Кількість схвалених ЛКО',
  notSet: 'Не встановлено',
  vip: 'ВИП',
  extraService: 'Додаткові послуги',
  reservationMissing: 'Бронювання, ймовірно, скасовано, перейдіть на вкладку "Загальне" замість цього.',
  confirmFor: 'Підтвердити до {{date}}',
  confirmAnotherDay: 'Підтвердити ще один день',
  taskConfirmed: 'Ви підтвердили завдання до {{date}}.',
  confirmYourTask: 'Potvrďte svoju úlohu',
  confirmYourTaskGX: 'Potvrďte svoju úlohu z GX',
  confirmHighPriorityTaskMessage:
    'Ви дійсно хочете встановити крайній термін на сьогодні? Ймовірно, завдання не буде підтверджено та виконано сьогодні.',
  setTomorrow: 'Встановити завтра',
  keepToday: 'Збережіть сьогодні',
  createTask: 'Створити завдання',
  confirmationNeeded: 'Підтвердження потрібно',
  pictureTooBlurry: `Зображення занадто розмите. Будь ласка, зробіть чітке фото і завантажте його.`,
  tryAgain: `Спробуйте ще раз`,
};
